import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../templates/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

const Careers = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allGraphCmsPosition {
          edges {
            node {
              slug
              id
              name
              location
            }
          }
        }
      }
    `
  )
  return (
    <Layout isHeaderFixed={false}>
      <SEO
        title="Careers"
        description="Get fast and reliable solution for your digital problem, We got you covered with all software from Website development to ECommerce site to Mobile app to AI enabled drones."
      />
      <div className="pt-8 pb-4 mx-4">
        <div className="text-center font-extrabold text-black md:text-5xl text-3xl mb-4 font-display">
          Open Positions
        </div>
      </div>
      <div className="mb-8 max-w-screen-xl md:mx-auto mx-4">
        <div className="my-8 max-w-4xl w-full mx-auto">
          {data.allGraphCmsPosition.edges.map(({ node }) => (
            <div className="my-2 p-6 rounded border flex flex-wrap justify-between items-center transition duration-300 ease-in-out hover:shadow-md cursor-pointer">
              <div>
                <div className="text-2xl font-bold mb-2">{node.name}</div>
                <div className="mb-2 overflow-x-auto">
                  Location:{" "}
                  {node.location.map(location => (
                    <span className="py-1 px-2 mr-1 rounded-full bg-yellow-100 text-yellow-800 text-sm whitespace-nowrap">
                      {`${location}`}
                    </span>
                  ))}
                </div>
              </div>
              <Link to={`position/${node.slug + "-" + node.id.split(":")[1]}`}>
                <button className="mt-2 md:mt-0 p-3 bg-primary-500 cursor-pointer text-center focus:outline-none text-white rounded hover:bg-primary-600">
                  Read more
                </button>
              </Link>
            </div>
          ))}
        </div>
        <div className="mb-4 text-lg">
          Not found any matched open positions?{" "}
          <span className="text-primary-500 cursor-pointer">
            <a href="mailto:hello@annwntech.com"> Apply here.</a>
          </span>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Careers
